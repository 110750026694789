<template>
  <vp-base-layout page-title="고객 약관 동의">
    <div class="l-wrapper">
      <div
        class="l-contents"
        style="margin: 10%">
        <p>중고 휴대폰 매각을 위해 아래의 약관 및 계약서에 동의해주세요.</p>
        
        <ion-item>
          <ion-label>
            전체 약관에 동의합니다.
          </ion-label>
          <ion-checkbox
            
            name="checkname"
            :checked="totalCheck"
            @ion-change="handleTotalCheck($event)" />
        </ion-item>
        
        <ion-item>
          <ion-label>
            (필수) 계약서 내용에 동의합니다.
          </ion-label>
          <ion-checkbox
            name="checkname"
            :checked="check1"
            @ion-change="handleCheck1($event)" />
        </ion-item>

        <ion-item>
          <ion-label>
            (필수) 블라블라
          </ion-label>
          <ion-checkbox
            name="checkname"
            :checked="check2"
            @ion-change="handleCheck2($event)" />
        </ion-item>
      </div>
      <div class="card-button">
        <vp-button
          expand="full"
          @click="handlePage(item)"
          :types="[{'fixed': fixed}]"
          :disabled="checkAll">
          다음
        </vp-button>
      </div>
    </div>
  </vp-base-layout>
</template>

<script setup>
//---------------------------------------------------------------------------------------------------------------------
// import
//---------------------------------------------------------------------------------------------------------------------
import { useStore } from 'vuex'
import { computed, ref } from 'vue'
import {
  IonItem,
  IonLabel,
  IonCheckbox,
  useIonRouter,
  alertController
} from '@ionic/vue'
import VpPageTitle from '@/components//original/layouts/VpPageTitle.vue'

const store = useStore()
const ionRouter = useIonRouter()
//---------------------------------------------------------------------------------------------------------------------
// 변수 선언 && 함수 선언
//---------------------------------------------------------------------------------------------------------------------
const fixed = ref(true)
const totalCheck = ref(false)
const check1 = ref(false)
const check2 = ref(false)
const checkAll = computed(() => {
  return totalCheck.value && check1.value && check2.value ? false:true
})

const handleTotalCheck = (event) => {
  if(event.detail.checked != totalCheck.value) {
    if(totalCheck.value) {
      totalCheck.value = false
      check1.value = false
      check2.value = false    
    } else {
      totalCheck.value = true      
      check1.value = true      
      check2.value = true 
    }
  }  
}

const handleCheck1 = (event) => {
  if(event.detail.checked != check1.value) {
    if(check1.value) {
      check1.value = false
    } else {
      check1.value = true
    }
  }
  fnTotalCheck()
}

const handleCheck2 = (event) => {
  if(event.detail.checked != check2.value) {
    if(check2.value) {
      check2.value = false
    } else {
      check2.value = true
    }
  }  
  fnTotalCheck()
}

const fnTotalCheck = async () => {
  if(check1.value && check2.value) {
    totalCheck.value = true
  } else {
    totalCheck.value = false
  }
}

const handleCheck = async() => {
    const alert = await alertController.create({
      //header: '약관동의',
      //subHeader: 'Important message',
      message: '전체 약관에 모두 동의해주세요.',
      buttons: ['확인']
    })
    await alert.present()
}

const handlePage = (item) => {
  if(!totalCheck.value && check1.value && check2.value) {
    handleCheck()
  }
  const data = {
    agree1_yn : check1.value == true ? 'Y' : 'N',
    agree2_yn : check2.value == true ? 'Y' : 'N'
  }
  store.dispatch('Customer/setAgreeYn', data)
  ionRouter.push('/mintit/agency/identification')
}
//---------------------------------------------------------------------------------------------------------------------
// life Cycle
//---------------------------------------------------------------------------------------------------------------------

</script>
